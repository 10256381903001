<template>
  <div style="width: 100%; height: calc(100vh - 10px);">
    <div ref="talkjs" style="width: 100%; height: 100%;">
      <i class="text-center">{{ str['processing'] }}</i>
    </div>
  </div>
</template>

<script>
  import Utils from '@/services/Utils'

  export default {
    name: 'talksjs',
    props: ['inbox'],
    data() {
      const user = Utils.getUser()
      return {
        user: user,
        str: window.strings,
      }
    },
    async mounted() {
      this.inbox.mount(this.$refs.talkjs)
    },
  }
</script>